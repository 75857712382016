import requestLogin from '@/utils/request-login'

//忘记密码发送短信验证码
export function smsForgetSend(mobile) {
    return requestLogin.get('/api-uaa/validata/sms/repassword-code/'+mobile)
}

//忘记密码校验验证码
export function validateForgetSend(params) {
    return requestLogin.get('/api-uaa/validata/sms/validate-code',params)
}

//忘记密码重置密码
export function resetForgetPassword(params) {
    return requestLogin.put('/api-user/users/reset-password',params)
}