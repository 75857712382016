<template>
  <a-form-model ref="resetForm" :model="form" :rules="rules" class="account-form">
    <a-form-model-item prop="newPassword" has-feedback>
      <a-input v-model="form.newPassword" placeholder="请输入密码" type="password">
        <img src="@/assets/images/login_password.png" width="16px" slot="prefix" />
      </a-input>
    </a-form-model-item>
    <a-form-model-item prop="confirmPassword" has-feedback>
      <a-input
        v-model="form.confirmPassword"
        placeholder="请再次输入密码"
        type="password"
      >
        <img src="@/assets/images/login_reset.png" width="16px" slot="prefix" />
      </a-input>
    </a-form-model-item>
    <a-form-model-item>
      <a-button type="primary" @click="submit"> 完成 </a-button>
    </a-form-model-item>
  </a-form-model>
</template>

<script>
import { resetForgetPassword } from "@/api/forget.js";
export default {
  name: "Confirm",
  data() {
    return {
      form: {},
      rules: {
        newPassword: [
          {
            required: true,
            message: "请输入密码",
            whitespace: true,
            trigger: ["change", "blur"],
          },
          {
            pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/,
            message: "6-20位，字母和数字组合",
            trigger: ["change", "blur"],
          },
        ],
        confirmPassword: [
          {
            required: true,
            message: "请输入密码",
            whitespace: true,
            trigger: ["change", "blur"],
          },
          {
            pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/,
            message: "6-20位，字母和数字组合",
            trigger: ["change", "blur"],
          },
          {
            validator: (rule, value, callback) => {
              if (value !== this.form.newPassword) {
                callback(new Error("两次密码不一样"));
              } else {
                callback();
              }
            },
            trigger: ["change", "blur"],
          },
        ],
      },
    };
  },
  methods: {
    //完成
    submit() {
      this.$refs.resetForm.validate((valid) => {
        if (valid) {
          this.form.mobile = this.$store.state.forgetMobile;
          resetForgetPassword(this.form).then((res) => {
            this.$message.success("重置成功，请重新登录");
            this.$router.push("/login");
          });
        }
      });
    },
  },
  created() {},
};
</script>

<style scoped lang="less">
.account-form {
  width: 100%;
  margin-top: 45px;
  ::v-deep .ant-input {
    border: 1px solid transparent;
    border-bottom: 1px solid #e8eaee;
  }
  ::v-deep .ant-input:hover {
    border-color: transparent;
    border-bottom: 1px solid #e8eaee;
    box-shadow: none;
  }
  ::v-deep .ant-input:focus {
    border-color: transparent;
    border-bottom: 1px solid #e8eaee;
    box-shadow: none;
  }
  ::v-deep .ant-input-affix-wrapper:hover {
    .ant-input:not(.ant-input-disabled) {
      border-color: transparent;
      border-bottom: 1px solid #e8eaee;
      box-shadow: none;
    }
  }
  ::v-deep .has-error {
    .ant-input {
      border-color: transparent;
      border-bottom: 1px solid #f5222d;
      box-shadow: none;
    }
    .ant-input:focus {
      border-color: transparent;
      border-bottom: 1px solid #f5222d;
      box-shadow: none;
    }
    .ant-input:hover {
      border-color: transparent;
      border-bottom: 1px solid #f5222d;
      box-shadow: none;
    }
  }
  ::v-deep .ant-input-affix-wrapper {
    .ant-input:not(:first-child) {
      padding-left: 40px;
    }
  }
  button {
    width: 100%;
    height: 36px;
    border-radius: 18px;
  }
  .wx {
    margin-left: 18px;
  }
}
</style>
