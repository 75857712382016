<template>
  <div class="right d-f f-d a-c">
    <a-menu v-model="current" mode="horizontal">
      <a-menu-item key="confirm" :disabled="!showConfirm">1.确认账号</a-menu-item>
      <a-menu-item key="reset" :disabled="!showReset">2.重置密码</a-menu-item>
    </a-menu>
    <confirm v-show="showConfirm" @canNext="getNextMessage"></confirm>
    <reset v-show="showReset"></reset>
  </div>
</template>

<script>
import Confirm from "./confirm.vue";
import Reset from "./reset.vue";
export default {
  name: "Forget",
  components: { Confirm, Reset },
  data() {
    return {
      current: ["confirm"],
      showConfirm: true,
      showReset: false,
    };
  },
  methods: {
    //收到可以下一步的指令
    getNextMessage() {
      this.showConfirm = false;
      this.showReset = true;
      this.current = ["reset"];
    },
  },
};
</script>

<style scoped lang="less">
.right {
  width: 445px;
  height: 613px;
  background-image: url("~@/assets/images/login_right_bac.png");
  background-size: 100% 100%;
  padding: 110px 48px 0;
  box-sizing: border-box;
  .ant-menu-horizontal {
    border-bottom: none;
  }
  .ant-menu-item {
    font-size: 16px;
  }
}
</style>
